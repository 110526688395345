/**
 * THIS FILE WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 * @codegen <<SignedSource::b5eb40f6aea97bcbbb771ec3de00b47c>>
 * @codegenCommand yarn build tokens
 */
export default `
html[data-color-mode="light"][data-theme~="light:legacy-dark"],
html[data-color-mode="dark"][data-theme~="dark:legacy-dark"] {
  color-scheme: dark;
  --ds-text: #E6EDFA;
  --ds-text-accent-lime: #4C6B1F;
  --ds-text-accent-lime-bolder: #37471F;
  --ds-text-accent-red: #FF5630;
  --ds-text-accent-red-bolder: #FF7452;
  --ds-text-accent-orange: #FEC57B;
  --ds-text-accent-orange-bolder: #F18D13;
  --ds-text-accent-yellow: #FFAB00;
  --ds-text-accent-yellow-bolder: #FFC400;
  --ds-text-accent-green: #36B37E;
  --ds-text-accent-green-bolder: #57D9A3;
  --ds-text-accent-teal: #00B8D9;
  --ds-text-accent-teal-bolder: #00C7E6;
  --ds-text-accent-blue: #0065FF;
  --ds-text-accent-blue-bolder: #2684FF;
  --ds-text-accent-purple: #6554C0;
  --ds-text-accent-purple-bolder: #8777D9;
  --ds-text-accent-magenta: #F797D2;
  --ds-text-accent-magenta-bolder: #FDD0EC;
  --ds-text-accent-gray: #8C9CB8;
  --ds-text-accent-gray-bolder: #ABBBD6;
  --ds-text-disabled: #1B2638;
  --ds-text-inverse: #0D1424;
  --ds-text-selected: #0052CC;
  --ds-text-brand: #0052CC;
  --ds-text-danger: #FF5630;
  --ds-text-warning: #172B4D;
  --ds-text-warning-inverse: #0D1424;
  --ds-text-success: #36B37E;
  --ds-text-discovery: #6554C0;
  --ds-text-information: #0065FF;
  --ds-text-subtlest: #7988A3;
  --ds-text-subtle: #9FB0CC;
  --ds-link: #0052CC;
  --ds-link-pressed: #0065FF;
  --ds-link-visited: #6554C0;
  --ds-link-visited-pressed: #6554C0;
  --ds-icon: #DCE5F5;
  --ds-icon-accent-lime: #6A9A23;
  --ds-icon-accent-red: #BF2600;
  --ds-icon-accent-orange: #F18D13;
  --ds-icon-accent-yellow: #FF8B00;
  --ds-icon-accent-green: #006644;
  --ds-icon-accent-teal: #008DA6;
  --ds-icon-accent-blue: #0747A6;
  --ds-icon-accent-purple: #403294;
  --ds-icon-accent-magenta: #DA62AC;
  --ds-icon-accent-gray: #B8C7E0;
  --ds-icon-disabled: #0d14245b;
  --ds-icon-inverse: #202B3D;
  --ds-icon-selected: #4C9AFF;
  --ds-icon-brand: #4C9AFF;
  --ds-icon-danger: #FF8F73;
  --ds-icon-warning: #FFF0B3;
  --ds-icon-warning-inverse: #202B3D;
  --ds-icon-success: #57D9A3;
  --ds-icon-discovery: #998DD9;
  --ds-icon-information: #B3D4FF;
  --ds-icon-subtle: #202B3D;
  --ds-border: #202B3D;
  --ds-border-accent-lime: #6A9A23;
  --ds-border-accent-red: #BF2600;
  --ds-border-accent-orange: #F18D13;
  --ds-border-accent-yellow: #FF8B00;
  --ds-border-accent-green: #006644;
  --ds-border-accent-teal: #008DA6;
  --ds-border-accent-blue: #0747A6;
  --ds-border-accent-purple: #403294;
  --ds-border-accent-magenta: #DA62AC;
  --ds-border-accent-gray: #B8C7E0;
  --ds-border-disabled: #0E1624;
  --ds-border-focused: #B3D4FF;
  --ds-border-input: #202B3D;
  --ds-border-inverse: #0D1424;
  --ds-border-selected: #0052CC;
  --ds-border-brand: #0052CC;
  --ds-border-danger: #DE350B;
  --ds-border-warning: #FF8B00;
  --ds-border-success: #006644;
  --ds-border-discovery: #403294;
  --ds-border-information: #0747A6;
  --ds-border-bold: #7988A3;
  --ds-background-accent-lime-subtlest: #2A3818;
  --ds-background-accent-lime-subtlest-hovered: #37471F;
  --ds-background-accent-lime-subtlest-pressed: #4C6B1F;
  --ds-background-accent-lime-subtler: #37471F;
  --ds-background-accent-lime-subtler-hovered: #4C6B1F;
  --ds-background-accent-lime-subtler-pressed: #5B7F24;
  --ds-background-accent-lime-subtle: #4C6B1F;
  --ds-background-accent-lime-subtle-hovered: #37471F;
  --ds-background-accent-lime-subtle-pressed: #2A3818;
  --ds-background-accent-lime-bolder: #94C748;
  --ds-background-accent-lime-bolder-hovered: #B3DF72;
  --ds-background-accent-lime-bolder-pressed: #D3F1A7;
  --ds-background-accent-red-subtlest: #BF2600;
  --ds-background-accent-red-subtlest-hovered: #DE350B;
  --ds-background-accent-red-subtlest-pressed: #FF5630;
  --ds-background-accent-red-subtler: #DE350B;
  --ds-background-accent-red-subtler-hovered: #FF5630;
  --ds-background-accent-red-subtler-pressed: #FF7452;
  --ds-background-accent-red-subtle: #FF5630;
  --ds-background-accent-red-subtle-hovered: #DE350B;
  --ds-background-accent-red-subtle-pressed: #BF2600;
  --ds-background-accent-red-bolder: #FF8F73;
  --ds-background-accent-red-bolder-hovered: #FFBDAD;
  --ds-background-accent-red-bolder-pressed: #FFEBE6;
  --ds-background-accent-orange-subtlest: #43290F;
  --ds-background-accent-orange-subtlest-hovered: #5F3811;
  --ds-background-accent-orange-subtlest-pressed: #974F0C;
  --ds-background-accent-orange-subtler: #5F3811;
  --ds-background-accent-orange-subtler-hovered: #974F0C;
  --ds-background-accent-orange-subtler-pressed: #B65C02;
  --ds-background-accent-orange-subtle: #974F0C;
  --ds-background-accent-orange-subtle-hovered: #5F3811;
  --ds-background-accent-orange-subtle-pressed: #4A2B0F;
  --ds-background-accent-orange-bolder: #F18D13;
  --ds-background-accent-orange-bolder-hovered: #FEC57B;
  --ds-background-accent-orange-bolder-pressed: #FFE2BD;
  --ds-background-accent-yellow-subtlest: #FF8B00;
  --ds-background-accent-yellow-subtlest-hovered: #FF991F;
  --ds-background-accent-yellow-subtlest-pressed: #FFAB00;
  --ds-background-accent-yellow-subtler: #FF991F;
  --ds-background-accent-yellow-subtler-hovered: #FFAB00;
  --ds-background-accent-yellow-subtler-pressed: #FFC400;
  --ds-background-accent-yellow-subtle: #FFAB00;
  --ds-background-accent-yellow-subtle-hovered: #FF991F;
  --ds-background-accent-yellow-subtle-pressed: #FF8B00;
  --ds-background-accent-yellow-bolder: #FFE380;
  --ds-background-accent-yellow-bolder-hovered: #FFF0B3;
  --ds-background-accent-yellow-bolder-pressed: #FFFAE6;
  --ds-background-accent-green-subtlest: #006644;
  --ds-background-accent-green-subtlest-hovered: #00875A;
  --ds-background-accent-green-subtlest-pressed: #36B37E;
  --ds-background-accent-green-subtler: #00875A;
  --ds-background-accent-green-subtler-hovered: #36B37E;
  --ds-background-accent-green-subtler-pressed: #57D9A3;
  --ds-background-accent-green-subtle: #36B37E;
  --ds-background-accent-green-subtle-hovered: #00875A;
  --ds-background-accent-green-subtle-pressed: #006644;
  --ds-background-accent-green-bolder: #79F2C0;
  --ds-background-accent-green-bolder-hovered: #ABF5D1;
  --ds-background-accent-green-bolder-pressed: #E3FCEF;
  --ds-background-accent-teal-subtlest: #008DA6;
  --ds-background-accent-teal-subtlest-hovered: #00A3BF;
  --ds-background-accent-teal-subtlest-pressed: #00B8D9;
  --ds-background-accent-teal-subtler: #00A3BF;
  --ds-background-accent-teal-subtler-hovered: #00B8D9;
  --ds-background-accent-teal-subtler-pressed: #00C7E6;
  --ds-background-accent-teal-subtle: #00B8D9;
  --ds-background-accent-teal-subtle-hovered: #00A3BF;
  --ds-background-accent-teal-subtle-pressed: #008DA6;
  --ds-background-accent-teal-bolder: #79E2F2;
  --ds-background-accent-teal-bolder-hovered: #B3F5FF;
  --ds-background-accent-teal-bolder-pressed: #E6FCFF;
  --ds-background-accent-blue-subtlest: #0747A6;
  --ds-background-accent-blue-subtlest-hovered: #0052CC;
  --ds-background-accent-blue-subtlest-pressed: #0065FF;
  --ds-background-accent-blue-subtler: #0052CC;
  --ds-background-accent-blue-subtler-hovered: #0065FF;
  --ds-background-accent-blue-subtler-pressed: #2684FF;
  --ds-background-accent-blue-subtle: #0065FF;
  --ds-background-accent-blue-subtle-hovered: #0052CC;
  --ds-background-accent-blue-subtle-pressed: #0747A6;
  --ds-background-accent-blue-bolder: #4C9AFF;
  --ds-background-accent-blue-bolder-hovered: #B3D4FF;
  --ds-background-accent-blue-bolder-pressed: #DEEBFF;
  --ds-background-accent-purple-subtlest: #403294;
  --ds-background-accent-purple-subtlest-hovered: #5243AA;
  --ds-background-accent-purple-subtlest-pressed: #6554C0;
  --ds-background-accent-purple-subtler: #5243AA;
  --ds-background-accent-purple-subtler-hovered: #6554C0;
  --ds-background-accent-purple-subtler-pressed: #8777D9;
  --ds-background-accent-purple-subtle: #6554C0;
  --ds-background-accent-purple-subtle-hovered: #5243AA;
  --ds-background-accent-purple-subtle-pressed: #403294;
  --ds-background-accent-purple-bolder: #998DD9;
  --ds-background-accent-purple-bolder-hovered: #C0B6F2;
  --ds-background-accent-purple-bolder-pressed: #EAE6FF;
  --ds-background-accent-magenta-subtlest: #341829;
  --ds-background-accent-magenta-subtlest-hovered: #50253F;
  --ds-background-accent-magenta-subtlest-pressed: #943D73;
  --ds-background-accent-magenta-subtler: #50253F;
  --ds-background-accent-magenta-subtler-hovered: #943D73;
  --ds-background-accent-magenta-subtler-pressed: #AE4787;
  --ds-background-accent-magenta-subtle: #943D73;
  --ds-background-accent-magenta-subtle-hovered: #50253F;
  --ds-background-accent-magenta-subtle-pressed: #421F34;
  --ds-background-accent-magenta-bolder: #E774BB;
  --ds-background-accent-magenta-bolder-hovered: #F797D2;
  --ds-background-accent-magenta-bolder-pressed: #FDD0EC;
  --ds-background-accent-gray-subtlest: #8C9CB8;
  --ds-background-accent-gray-subtlest-hovered: #9FB0CC;
  --ds-background-accent-gray-subtlest-pressed: #ABBBD6;
  --ds-background-accent-gray-subtler: #9FB0CC;
  --ds-background-accent-gray-subtler-hovered: #8C9CB8;
  --ds-background-accent-gray-subtler-pressed: #7988A3;
  --ds-background-accent-gray-subtle: #ABBBD6;
  --ds-background-accent-gray-subtle-hovered: #B8C7E0;
  --ds-background-accent-gray-subtle-pressed: #CED9EB;
  --ds-background-accent-gray-bolder: #CED9EB;
  --ds-background-accent-gray-bolder-hovered: #B8C7E0;
  --ds-background-accent-gray-bolder-pressed: #ABBBD6;
  --ds-background-disabled: #3B475C;
  --ds-background-input: #0E1624;
  --ds-background-input-hovered: #1B2638;
  --ds-background-input-pressed: #0D1424;
  --ds-background-inverse-subtle: #FFFFFF29;
  --ds-background-inverse-subtle-hovered: #FFFFFF3D;
  --ds-background-inverse-subtle-pressed: #FFFFFF52;
  --ds-background-neutral: #3B475C;
  --ds-background-neutral-hovered: #313D52;
  --ds-background-neutral-pressed: #B3D4FF;
  --ds-background-neutral-subtle: transparent;
  --ds-background-neutral-subtle-hovered: #313D52;
  --ds-background-neutral-subtle-pressed: #B3D4FF;
  --ds-background-neutral-bold: #CED9EB;
  --ds-background-neutral-bold-hovered: #B8C7E0;
  --ds-background-neutral-bold-pressed: #DCE5F5;
  --ds-background-selected: #DEEBFF;
  --ds-background-selected-hovered: #B3D4FF;
  --ds-background-selected-pressed: #4C9AFF;
  --ds-background-selected-bold: #0052CC;
  --ds-background-selected-bold-hovered: #0065FF;
  --ds-background-selected-bold-pressed: #0747A6;
  --ds-background-brand-subtlest: #0747A6;
  --ds-background-brand-subtlest-hovered: #0052CC;
  --ds-background-brand-subtlest-pressed: #0747A6;
  --ds-background-brand-bold: #0052CC;
  --ds-background-brand-bold-hovered: #0065FF;
  --ds-background-brand-bold-pressed: #0747A6;
  --ds-background-brand-boldest: #B3D4FF;
  --ds-background-brand-boldest-hovered: #DEEBFF;
  --ds-background-brand-boldest-pressed: #4C9AFF;
  --ds-background-danger: #FFEBE6;
  --ds-background-danger-hovered: #FFBDAD;
  --ds-background-danger-pressed: #FF8F73;
  --ds-background-danger-bold: #FF5630;
  --ds-background-danger-bold-hovered: #FF7452;
  --ds-background-danger-bold-pressed: #DE350B;
  --ds-background-warning: #FFFAE6;
  --ds-background-warning-hovered: #FFF0B3;
  --ds-background-warning-pressed: #FFE380;
  --ds-background-warning-bold: #FFAB00;
  --ds-background-warning-bold-hovered: #FFC400;
  --ds-background-warning-bold-pressed: #FF991F;
  --ds-background-success: #E3FCEF;
  --ds-background-success-hovered: #ABF5D1;
  --ds-background-success-pressed: #79F2C0;
  --ds-background-success-bold: #00875A;
  --ds-background-success-bold-hovered: #36B37E;
  --ds-background-success-bold-pressed: #006644;
  --ds-background-discovery: #EAE6FF;
  --ds-background-discovery-hovered: #C0B6F2;
  --ds-background-discovery-pressed: #998DD9;
  --ds-background-discovery-bold: #5243AA;
  --ds-background-discovery-bold-hovered: #6554C0;
  --ds-background-discovery-bold-pressed: #403294;
  --ds-background-information: #DEEBFF;
  --ds-background-information-hovered: #B3D4FF;
  --ds-background-information-pressed: #4C9AFF;
  --ds-background-information-bold: #0052CC;
  --ds-background-information-bold-hovered: #0065FF;
  --ds-background-information-bold-pressed: #0747A6;
  --ds-blanket: #0d1424a0;
  --ds-blanket-selected: #1D7AFC14;
  --ds-blanket-danger: #E3493514;
  --ds-interaction-hovered: #ffffff33;
  --ds-interaction-pressed: #ffffff5c;
  --ds-skeleton: #0d1424f2;
  --ds-skeleton-subtle: #0d1424f7;
  --ds-chart-categorical-1: #00B8D9;
  --ds-chart-categorical-1-hovered: #00C7E6;
  --ds-chart-categorical-2: #6554C0;
  --ds-chart-categorical-2-hovered: #8777D9;
  --ds-chart-categorical-3: #D97008;
  --ds-chart-categorical-3-hovered: #F18D13;
  --ds-chart-categorical-4: #F797D2;
  --ds-chart-categorical-4-hovered: #FDD0EC;
  --ds-chart-categorical-5: #0065FF;
  --ds-chart-categorical-5-hovered: #2684FF;
  --ds-chart-categorical-6: #6554C0;
  --ds-chart-categorical-6-hovered: #8777D9;
  --ds-chart-categorical-7: #FDD0EC;
  --ds-chart-categorical-7-hovered: #FFECF8;
  --ds-chart-categorical-8: #FEC57B;
  --ds-chart-categorical-8-hovered: #FFE2BD;
  --ds-chart-lime-bold: #6A9A23;
  --ds-chart-lime-bold-hovered: #5B7F24;
  --ds-chart-lime-bolder: #5B7F24;
  --ds-chart-lime-bolder-hovered: #4C6B1F;
  --ds-chart-lime-boldest: #4C6B1F;
  --ds-chart-lime-boldest-hovered: #37471F;
  --ds-chart-neutral: #B8C7E0;
  --ds-chart-neutral-hovered: #ABBBD6;
  --ds-chart-red-bold: #BF2600;
  --ds-chart-red-bold-hovered: #DE350B;
  --ds-chart-red-bolder: #DE350B;
  --ds-chart-red-bolder-hovered: #FF5630;
  --ds-chart-red-boldest: #FF5630;
  --ds-chart-red-boldest-hovered: #FF7452;
  --ds-chart-orange-bold: #F18D13;
  --ds-chart-orange-bold-hovered: #FAA53D;
  --ds-chart-orange-bolder: #FAA53D;
  --ds-chart-orange-bolder-hovered: #FEC57B;
  --ds-chart-orange-boldest: #FEC57B;
  --ds-chart-orange-boldest-hovered: #FFE2BD;
  --ds-chart-yellow-bold: #FF8B00;
  --ds-chart-yellow-bold-hovered: #FF991F;
  --ds-chart-yellow-bolder: #FF991F;
  --ds-chart-yellow-bolder-hovered: #FFAB00;
  --ds-chart-yellow-boldest: #FFAB00;
  --ds-chart-yellow-boldest-hovered: #FFC400;
  --ds-chart-green-bold: #006644;
  --ds-chart-green-bold-hovered: #00875A;
  --ds-chart-green-bolder: #00875A;
  --ds-chart-green-bolder-hovered: #36B37E;
  --ds-chart-green-boldest: #36B37E;
  --ds-chart-green-boldest-hovered: #57D9A3;
  --ds-chart-teal-bold: #008DA6;
  --ds-chart-teal-bold-hovered: #00A3BF;
  --ds-chart-teal-bolder: #00A3BF;
  --ds-chart-teal-bolder-hovered: #00B8D9;
  --ds-chart-teal-boldest: #00B8D9;
  --ds-chart-teal-boldest-hovered: #00C7E6;
  --ds-chart-blue-bold: #0747A6;
  --ds-chart-blue-bold-hovered: #0052CC;
  --ds-chart-blue-bolder: #0052CC;
  --ds-chart-blue-bolder-hovered: #0065FF;
  --ds-chart-blue-boldest: #0065FF;
  --ds-chart-blue-boldest-hovered: #2684FF;
  --ds-chart-purple-bold: #403294;
  --ds-chart-purple-bold-hovered: #5243AA;
  --ds-chart-purple-bolder: #5243AA;
  --ds-chart-purple-bolder-hovered: #6554C0;
  --ds-chart-purple-boldest: #6554C0;
  --ds-chart-purple-boldest-hovered: #8777D9;
  --ds-chart-magenta-bold: #CD519D;
  --ds-chart-magenta-bold-hovered: #DA62AC;
  --ds-chart-magenta-bolder: #DA62AC;
  --ds-chart-magenta-bolder-hovered: #E774BB;
  --ds-chart-magenta-boldest: #F797D2;
  --ds-chart-magenta-boldest-hovered: #FDD0EC;
  --ds-chart-gray-bold: #B8C7E0;
  --ds-chart-gray-bold-hovered: #CED9EB;
  --ds-chart-gray-bolder: #CED9EB;
  --ds-chart-gray-bolder-hovered: #DCE5F5;
  --ds-chart-gray-boldest: #DCE5F5;
  --ds-chart-gray-boldest-hovered: #E6EDFA;
  --ds-chart-brand: #0052CC;
  --ds-chart-brand-hovered: #0065FF;
  --ds-chart-danger: #DE350B;
  --ds-chart-danger-hovered: #FF5630;
  --ds-chart-danger-bold: #FF5630;
  --ds-chart-danger-bold-hovered: #FF7452;
  --ds-chart-warning: #FF991F;
  --ds-chart-warning-hovered: #FFAB00;
  --ds-chart-warning-bold: #FFAB00;
  --ds-chart-warning-bold-hovered: #FFC400;
  --ds-chart-success: #00875A;
  --ds-chart-success-hovered: #36B37E;
  --ds-chart-success-bold: #36B37E;
  --ds-chart-success-bold-hovered: #57D9A3;
  --ds-chart-discovery: #5243AA;
  --ds-chart-discovery-hovered: #6554C0;
  --ds-chart-discovery-bold: #6554C0;
  --ds-chart-discovery-bold-hovered: #8777D9;
  --ds-chart-information: #0052CC;
  --ds-chart-information-hovered: #0065FF;
  --ds-chart-information-bold: #0065FF;
  --ds-chart-information-bold-hovered: #2684FF;
  --ds-surface: #1B2638;
  --ds-surface-hovered: #202B3D;
  --ds-surface-pressed: #283447;
  --ds-surface-overlay: #7988A3;
  --ds-surface-overlay-hovered: #8C9CB8;
  --ds-surface-overlay-pressed: #9FB0CC;
  --ds-surface-raised: #455166;
  --ds-surface-raised-hovered: #56637A;
  --ds-surface-raised-pressed: #67758F;
  --ds-surface-sunken: #67758F;
  --ds-shadow-overflow: 0px 0px 12px #0304048F, 0px 0px 1px #03040480;
  --ds-shadow-overflow-perimeter: #03040480;
  --ds-shadow-overflow-spread: #0304048f;
  --ds-shadow-overlay: inset 0px 0px 0px 1px #0d14240A, 0px 8px 12px #0d14245C, 0px 0px 1px #0d142480;
  --ds-shadow-raised: inset 0px 0px 0px 1px #00000000, 0px 1px 1px #0d142480, 0px 0px 1px #0d142480;
  --ds-opacity-disabled: 0.4;
  --ds-opacity-loading: 0.2;
  --ds-UNSAFE-transparent: transparent;
  --ds-elevation-surface-current: #1B2638;
}
`;
